import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX } from './path-consts/prefix.env';
import { SERVICE_ENV } from './path-consts/service.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  FE_URL: `https://tmtrav-shop-admin-${PREFIX.DEV}.tma-swerp.com/`,
  FE_URL_SALE: `https://tmtrav-ecommerce-${PREFIX.DEV}.tma-swerp.com/shopping`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  SERVICES: SERVICE_ENV,
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'TMTravel-white',
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  ECOMMERCE_MANAGEMENT_DOMAIN_URL: `https://tmtrav-ecommerce-management-${PREFIX.DEV}.tma-swerp.com`,
  APP_NAME: 'TMTravel',
  IFRAME_BANNER_MANAGEMENT: `https://tmtrav-banner-${PREFIX.DEV}.tma-swerp.com/shop`,
  SPECIAL_SELLER_DOMAIN_URL: "https://home-connect-special-seller.tma-swerp.com"
};
